import axios from "axios";

const baseUrl = `/v1/apps`;

const list = async (id = null) => {
  try {
    let appsResponse = null;
    if (id) {
      appsResponse = await axios.get(`${baseUrl}/${id}`);
    } else {
      appsResponse = await axios.get(`${baseUrl}`);
    }
    return appsResponse.data;
  } catch (e) {
    // only for status code 401 (unauthorized) we return null as not logged in indicator
    if (e.response.status === 401) {
      return null;
    }

    console.log(
      `[Apps] failed retrieving apps of user, status code: ${e.response.status}`
    );
    throw e;
  }
};

const createApp = async (data) => {
  try {
    const createResponse = await axios.post(`${baseUrl}`, { ...data });
    return createResponse.data;
  } catch (e) {
    console.log(
      `[Apps] failed creating new app, status code: ${e.response.status}`
    );
    throw e;
  }
};

const updateKeys = async (id) => {
  try {
    const updateResponse = await axios.post(`${baseUrl}/${id}/update/secrets`);
    return updateResponse.data;
  } catch (e) {
    console.log(
      `[Apps] failed regenerating keys, status code: ${e.response.status}`
    );
    throw e;
  }
};

const updateDisplay = async (id, data) => {
  try {
    const updateResponse = await axios.post(`${baseUrl}/${id}/update/display`, {
      ...data,
    });
    return updateResponse.data;
  } catch (e) {
    console.log(
      `[Apps] failed updating display info, status code: ${e.response.status}`
    );
    throw e;
  }
};

const deleteApp = async (id) => {
  try {
    const deleteResponse = await axios.post(`${baseUrl}/${id}/delete`);
    return deleteResponse.data;
  } catch (e) {
    console.log(
      `[Apps] failed deleting app, status code: ${e.response.status}`
    );
    throw e;
  }
};

const updateStatus = async (data) => {
  try {
    const updateResponse = await axios.post(
      `${baseUrl}/${data.id}/update/status`,
      {
        status: data.status,
      }
    );
    return updateResponse.data;
  } catch (e) {
    console.log(
      `[Apps] failed updating display info, status code: ${e.response.status}`
    );
    throw e;
  }
};

const updateIntegration = async (id, data) => {
  try {
    const updateResponse = await axios.post(
      `${baseUrl}/${id}/update/integration`,
      {
        ...data,
      }
    );
    return updateResponse.data;
  } catch (e) {
    console.log(
      `[Apps] failed updating display info, status code: ${e.response.status}`
    );
    throw e;
  }
};
export const appsApi = {
  list,
  createApp,
  updateKeys,
  deleteApp,
  updateDisplay,
  updateStatus,
  updateIntegration,
};
