import { appsApi } from "@/api/apps";
import { marketplaceApi } from "@/api/marketplace";

export default {
  namespaced: true,
  state() {
    return {
      selectedApp: null,
      marketplaceStatus: null
    };
  },
  getters: {
    app(state) {
      return state.selectedApp;
    },
    marketplaceStatus(state) {
      return state.marketplaceStatus;
    },
    isPublishedInMarketplace(state) {
      return state.marketplaceStatus?.submission_status === "Published";
    },
    isSuspendedFromMarketplace(state) {
      return state.marketplaceStatus?.submission_status === "Suspended";
    },
    isRejectedFromMarketplace(state) {
      return state.marketplaceStatus?.submission_status === "Declined";
    },
    isSuspended(state) {
      return state.selectedApp.status === "Suspended";
    },
    isDisplayInfoConfigured(state) {
      return state.selectedApp.display_details.description && state.selectedApp.display_details.short_description;
    },
    isOauthConfigured(state) {
      return state.selectedApp.oauth_details.oauth_redirect_url && state.selectedApp.oauth_details.oauth_scopes && state.selectedApp.canvas_url;
    },
    isAppPublished(state) {
      return state.selectedApp.status === "Published";
    },
    setupCurrentStep(state, getters) {
      if (!getters["isDisplayInfoConfigured"]) {
        return 1;
      }

      if (!getters["isOauthConfigured"]) {
        return 2;
      }

      if (!getters["isAppPublished"]) {
        return 3;
      }

      return 4;
    },
    marketplaceCurrentStep(state, getters) {
      if (state.marketplaceStatus) {
        return 3;
      }

      if (getters["setupCurrentStep"] < 4) {
        return 1;
      }

      return 2;
    }
  },
  mutations: {
    updateSelectedApp(state, payload) {
      state.selectedApp = payload;
    },
    updateAppStatus(state, payload) {
      state.selectedApp.status = payload;
    },
    updateOauthDetails(state, payload) {
      state.selectedApp.oauth_details = payload;
    },
    updateDisplay(state, payload) {
      state.selectedApp.display_details = payload;
    },
    updateMarketplace(state, payload) {
      state.marketplaceStatus = payload;
    },
    updateIntegration(state, payload) {
      state.selectedApp.canvas_url = payload.canvas_url;
      state.selectedApp.oauth_details.oauth_redirect_url =
        payload.oauth_redirect_url;
      state.selectedApp.oauth_details.oauth_scopes = payload.oauth_scopes;
    },
    removeSelected(state) {
      state.selectedApp = null;
      state.marketplaceStatus = null;
    }
  },
  actions: {
    async fetchApps(context) {
      try {
        return await appsApi.list();
      } catch (e) {
        context.commit("error", e);
      }
    },
    async loadApp(context, payload) {
      await context.dispatch("fetchFormProposal", payload);
      await context.dispatch("fetchAppById", payload);

      return context.getters["app"];
    },
    async updateStatus(context, payload) {
      try {
        const response = await appsApi.updateStatus({
          id: payload.id,
          status: payload.status
        });

        if (response.status) {
          context.commit("updateAppStatus", payload.status);
          return true;
        }
      } catch (e) {
        context.commit("error", e);
      }

      return false;
    },
    async fetchAppById(context, payload) {
      try {
        context.commit("updateSelectedApp", await appsApi.list(payload.id));
        return context.state.selectedApp;
      } catch (e) {
        context.commit("error", e);
      }
    },
    async createApp(context, payload) {
      try {
        return await appsApi.createApp(payload);
      } catch (e) {
        context.commit("error", e);
      }
    },
    async integrate(context, payload) {
      try {
        return await appsApi.updateIntegration(payload.id, payload.data);
      } catch (e) {
        context.commit("error", e);
      }
    },
    async fetchFormProposal(context, payload) {
      try {
        context.commit("updateMarketplace", await marketplaceApi.formProposal(payload.id));
        return context.state.marketplaceStatus;
      } catch (e) {
        context.commit("error", e);
      }
    },
    async createMarketplace(context, payload) {
      try {
        const response = await marketplaceApi.createMarketplace(payload);

        if (response.status) {
          await context.dispatch("fetchFormProposal", { id: payload.app_id });
        }
      } catch (e) {
        context.commit("error", e);
      }
    },
    async updateDisplayData(context, payload) {
      try {
        return await appsApi.updateDisplay(payload.id, payload.data);
      } catch (e) {
        context.commit("error", e);
      }
    },
    async updateSecrets(context, payload) {
      try {
        const response = await appsApi.updateKeys(payload.id);

        if (response.status) {
          context.commit("updateOauthDetails", response.oauth_details);
          return true;
        }
      } catch (e) {
        context.commit("error", e);
      }

      return false;
    },
    async deleteApp(context, payload) {
      try {
        return await appsApi.deleteApp(payload.id);
      } catch (e) {
        context.commit("error", e);
      }
    }
  }
};
