import axios from "axios";
import { startFlowStages } from "@/config/model";

const baseUrl = `/v1/auth`;

const startAuthentication = async (email) => {
  const startResponse = await axios.post(`${baseUrl}/start`, {
    email
  });

  const nextStep = startResponse.data.next_step;

  // Validate that returned next step from server is mapped to an existing login/signup stages flow
  if (!Object.values(startFlowStages).includes(nextStep)) {
    throw new Error(`invalid start decision. step: ${nextStep}`);
  }

  return nextStep;
};

const passwordLogin = async (password) => {
  const loginResponse = await axios.post(`${baseUrl}/passwordLogin`, {
    password
  });

  return loginResponse.data;
};

const validateOtp = async (code) => {
  const otpResponse = await axios.post(`${baseUrl}/otp`, {
    code
  });

  return otpResponse.data;
};

const googleLogin = async (payload) => {
  const googleLoginResponse = await axios.post(`${baseUrl}/googleLogin`, {
    ...payload
  });

  return googleLoginResponse.data;
};

const completeSignup = async (firstName, lastName, companyName) => {
  const completeSignupResponse = await axios.post(`${baseUrl}/completeSignup`, {
    first_name: firstName,
    last_name: lastName,
    company_name: companyName
  });

  return completeSignupResponse.data;
};

const invite = async (token) => {
  try {
    const authWithInviteResponse = await axios.post(`${baseUrl}/invite`, {
      token: token
    });

    return authWithInviteResponse.data;
  } catch (e) {
    if (e.response.status === 401) {
      return {
        status: false
      };
    }

    throw e;
  }
};

export const authApi = {
  startAuthentication,
  passwordLogin,
  validateOtp,
  googleLogin,
  completeSignup,
  invite
};

