export const startFlowStages = {
  // onboarding (login & signup) flow stages
  emailPrompt: "email-prompt",
  passwordLogin: "password-login",
  otp: "email-otp",
  completeSignup: "complete-signup",

  // end stages
  welcomeUser: "welcome-user",
  successLogin: "success-login",
  error: "error",
};