import { createApp } from "vue";
import GoogleSignInPlugin from "vue3-google-signin"
import App from "@/App.vue";
import store from "@/store";
import router from "@/router";

const app = createApp(App);

app.use(GoogleSignInPlugin, {
  clientId: '717501384852-9j3uglcvtvhm0ortp06vepju1kagjjv1.apps.googleusercontent.com',
});

app
  .use(store)
  .use(router)
  .mount("#app");