import axios from "axios";

const baseUrl = `/v1/marketplace`;

const formProposal = async (id) => {
  try {
    const submitStatusResponse = await axios.get(`${baseUrl}/submit/${id}`);
    return submitStatusResponse.data;
  } catch (e) {
    if (e.response.status === 404) {
      return null;
    }

    console.log(
      `[Marketplace] failed retrieving marketplace submission request, status code: ${e.response.status}`
    );
    throw e;
  }
};

const createMarketplace = async (data) => {
  try {
    const createResponse = await axios.post(`${baseUrl}/submit`, {
      ...data,
    });

    return createResponse.data;
  } catch (e) {
    console.log(
      `[Marketplace] failed submitting new app request, status code: ${e.response.status}`
    );
    throw e;
  }
};

export const marketplaceApi = {
  createMarketplace,
  formProposal,
};
