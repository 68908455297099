import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("@/pages/home/HomePage.vue"),
  },
  {
    path: "/terms_and_conditions/",
    component: () => import("@/pages/home/TermsAndConditions.vue"),
  },
  {
    path: "/get-started/",
    component: () => import("@/pages/GetStarted.vue"),
  },
  {
    path: "/user/",
    component: () => import("@/pages/User.vue"),
  },
  {
    path: "/not-found/",
    component: () => import("@/pages/home/NotFound.vue"),
  },
  {
    path: "/apps/:id",
    component: () => import("@/pages/AppConfig.vue"),
    children: [
      {
        path: "/apps/:id/info",
        component: () => import("@/pages/config/AppInfo.vue"),
      },
      {
        path: "/apps/:id/marketplace",
        component: () => import("@/pages/config/AppFormProposal.vue"),
      },
      {
        path: "/apps/:id/integration",
        component: () => import("@/pages/config/AppIntegration.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/pages/home/NotFound.vue"),
  },
  {
    path: "/apps/",
    component: () => import("@/pages/Apps.vue"),
  },
  //{
  //  path: "/apps/:id",
  //  component: () => import("@/pages/AppConfig.vue"),
  //},
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

export default router;
