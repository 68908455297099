import axios from "axios";

const baseUrl = `/v1/user`;

const me = async () => {
  try {
    const profileResponse = await axios.get(`${baseUrl}/me`);
    return profileResponse.data;
  } catch (e) {
    // only for status code 401 (unauthorized) we return null as not logged in indicator
    if (e.response.status === 401) {
      return null;
    }

    console.log(
      `[User] failed retrieving user profile, status code: ${e.response.status}`
    );
    throw e;
  }
};

const inviteDetails = async (token) => {
  try {
    const inviteDetailsResponse = await axios.get(`${baseUrl}/invite/${token}`);
    return inviteDetailsResponse.data;
  } catch (e) {
    if (e.response.status === 401) {
      return {
        status: false,
      };
    }

    console.log(
      `[User] failed retrieving invite token details, status code: ${e.response.status}`
    );
    throw e;
  }
};

const updateProfile = async (params) => {
  try {
    await axios.post(`${baseUrl}/profile`, {
      ...params,
    });
    return {
      status: true,
    };
  } catch (e) {
    if (e.response.status === 401) {
      return {
        status: false,
      };
    }

    console.log(
      `[User] failed retrieving invite token details, status code: ${e.response.status}`
    );
    throw e;
  }
};

const acceptInvite = async (token) => {
  try {
    const acceptResponse = await axios.post(
      `${baseUrl}/invite/${token}/accept`
    );
    return acceptResponse.data;
  } catch (e) {
    console.log(
      `[User] failed retrieving invite token details, status code: ${e.response.status}`
    );
    throw e;
  }
};

const logout = async () => {
  return await axios.post(`${baseUrl}/logout`);
};

const tokens = async () => {
  try {
    const tokensResponse = await axios.get(`${baseUrl}/token`);
    return tokensResponse.data;
  } catch (e) {
    // only for status code 401 (unauthorized) we return null as not logged in indicator
    if (e.response.status === 401) {
      return null;
    }

    console.log(
      `[User] failed retrieving tokens, status code: ${e.response.status}`
    );
    throw e;
  }
};

const createToken = async (params) => {
  try {
    return await axios.post(`${baseUrl}/token`, {
      ...params,
    });
  } catch (e) {
    console.log(
      `[User] failed creating access token details, status code: ${e.response.status}`
    );

    throw e;
  }
};

const removeToken = async (params) => {
  try {
    const response = await axios.delete(`${baseUrl}/token/${params.id}`);
    if (response && response.status === 201)
      return {
        status: true,
      };
  } catch (e) {
    if (e.response.status === 401) {
      return {
        status: false,
      };
    }

    console.log(
      `[User] failed retrieving invite token details, status code: ${e.response.status}`
    );
    throw e;
  }
};

export const userApi = {
  me,
  logout,
  inviteDetails,
  acceptInvite,
  updateProfile,
  tokens,
  createToken,
  removeToken,
};
